import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import { ITopicPath } from '../components/atoms/topicPath'
import CategoryCard from '../components/molecules/categoryCard'
import Layout from '../components/organisms/layout'
import useHelpData from '../hooks/useHelpData'
import BreakPoints from '../styles/breakPoints'
import { CategoryKindType } from '../types/CategoryKind'
import useRouteParam from '../hooks/useRouteParam'

type IProps = PageProps<GatsbyTypes.CategoriesPageQuery>

const Wrapper = styled.div`
  .category-card {
    position: relative;
    margin-top: 40px;
    display: flex;
    width: 100%;

    .category-title {
      left: 10px;
      position: absolute;
      top: -40px;
    }

    .category-list {
      width: 100%;
      margin-left: 15px;

      li {
        line-height: 2rem;

        &::before {
          top: 40%;
          left: -15px;
        }
      }

      a {
        font-size: 1.2rem;
      }
    }
  }

  ${BreakPoints.medium} {
    .category-card {
      width: calc(100vw - 20px);
    }
  }
`

/**
 * カテゴリ一覧ページ
 * @constructor
 */
const CategoriesPage = ({ data, location }: IProps) => {
  const helpCategoryId = data.awsGraphQL.getHelpCategory?.helpCategoryId || ''
  const categoryTitle = data.awsGraphQL.getHelpCategory?.title || ''
  const categoryKind = data.awsGraphQL.getHelpCategory
    ?.categoryKind as CategoryKindType
  const slug = data.awsGraphQL.getHelpCategory?.slug || ''
  const { helpCategoryMap } = useHelpData()
  const category = helpCategoryMap[helpCategoryId]

  // パンくず情報
  const paths: ITopicPath[] = [
    { title: 'TOP', link: useRouteParam('/help') },
    { title: category?.title },
  ]

  return (
    <Wrapper>
      <Layout location={location} paths={paths} categoryKind={categoryKind}>
        <SEO title={categoryTitle} />
        <CategoryCard
          categoryTitle={categoryTitle}
          slug={slug}
          helpCategoryId={helpCategoryId}
          limit={100}
        />
      </Layout>
    </Wrapper>
  )
}

export default CategoriesPage

export const pageQuery = graphql`
  query CategoriesPage($id: ID!) {
    awsGraphQL {
      getHelpCategory(id: $id) {
        helpCategoryId
        categoryKind
        title
        slug
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
